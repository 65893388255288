import React, { useState} from 'react';

const ImageFadeIn = ({ src, alt, className }) => {
    const [loaded, setLoaded] = useState(false);
  
    // Combine additional class names with the internal fade-in class
    const fullClassName = `fade-in ${loaded ? 'visible' : ''} ${className || ''}`;
  
    return (
      <img
        src={src}
        alt={alt}
        className={fullClassName}
        onLoad={() => setLoaded(true)}
        style={{ width: '100%', height: 'auto' }} // Default styles, can be overridden or extended via className
      />
    );
  };
  
  export default ImageFadeIn;
