import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const checkAuth = (WrappedComponent, requiredPermission) => {
  return (props) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    useEffect(() => {
      const validateToken = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}ping/${requiredPermission}`, {
            method: 'GET',
            credentials: 'include',
          });

          if (response.ok) {
            setIsAuthenticated(true);
          } else {
            if (requiredPermission === 'aaf') {
              await sleep(100);
              alert('Please Login to use this feature.')
            }
            
            setIsAuthenticated(false);
          }
        } catch (error) {
          setIsAuthenticated(false);
        }
      };

      validateToken();
    }, []);

    if (isAuthenticated === null) {
      return null
    }

    if (isAuthenticated === false) {
      return <Redirect to="/" />;
    } else {
      return <WrappedComponent {...props} />;
    }
  };
};

export default checkAuth;
