import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

const Recover = () => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();


  const handleCancel = () => {
    history.push('/login');
  };

  const handleConfirm = useCallback(async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage('');

    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}recover-account?email=${encodeURIComponent(email)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    });

    const responseData = await response.json();
    if (response.ok) {
      history.push('/mfa?origin=recover');
    } else {
      setIsSubmitting(false);
      setEmail('');
      setErrorMessage(responseData.error);
    }
  }, [email,history]);

  return (
    <div className="wrapper">
      <div className="mfa-container">
        <div className="mfa-box">
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
            <a href="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                <img style={{ maxHeight: '50px', marginRight: '10px' }} src="/assets/img/logo.png" alt="Logo" />
            </a>
        </div>
          <h3>Recover Your Password</h3>
          <p>Please enter your email address to search for your account.</p>
          <h2>Note: The recovery of password may take up to 48 hours. During this time, your account will be frozen.</h2>
          <form onSubmit={handleConfirm}>
            <input
              type="email"
              placeholder="Email address"
              className="mfa-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {errorMessage && <p className="error-message" style={{ color: 'red' }}>{errorMessage}</p>}
            <div className="button-group">
              <button type="button" className="cancel-button" onClick={handleCancel}>CANCEL</button>
              <button
                type="submit"
                className={`btn bg-gradient-info mb-0 search-button font20 ${isSubmitting ? 'dimmed' : ''}`}
              >
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Recover;
