import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import backgroundImage from './preload_pic/login-img-old-min.jpg'; // Adjust the path if necessary

const link = document.createElement('link');
link.rel = 'preload';
link.as = 'image';
link.href = backgroundImage;
document.head.appendChild(link);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
