
const logout = async (e) => {
    if (e) e.preventDefault();
    localStorage.clear();
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}logout`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    const data = await response.text();
    if (response.ok) {
        window.location.href = "/login";
    } else {
        alert(data);
    }
};

export {
    logout
}