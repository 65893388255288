import React, { Suspense } from 'react';
import { Routes } from '../routes';
import { Switch } from 'react-router-dom'
/*import Sidebar from "./sidebar";*/
import NavBar from "./navbar";
import ImageFadeIn from './imagefadein';
import {Route} from 'react-router-dom';
import { Link } from 'react-router-dom';
import backgroundImage from '../preload_pic/login-img-old-min.jpg'; // Adjust the path if necessary

const Base = ({ isHomepage }) => {
    const backgroundStyle = isHomepage ? {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'scroll'
    } : {};
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <React.Fragment>
            <main className="main-content position-relative max-height-vh-100 h-100 " style={backgroundStyle}>
                <NavBar />
                {isHomepage && (

                    <><div id="root" style={{
                        display: 'flex',
                        flexDirection: 'column', // Aligns children vertically
                        justifyContent: 'center', // Centers content vertically in the container
                        alignItems: 'center',
                        height: '100vh', 
                        color: 'white',
                        fontStyle: 'italic'
                    }}>
                        <p className="tagline">
                            Streamlining VFX Editing with Functional AI
                        </p>
                    </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '5rem', backgroundColor: 'white', marginTop: '-13rem' }}>
                            {/* Image container for Convert part */}

                            <div class="image-container">
                                <ImageFadeIn
                                    src="../../assets/img/bg1.jpg"
                                    alt="Feature"
                                    className="feature-image"
                                />
                            </div>

                            {/* Text container */}
                            <div style={{
                                flex: 1,
                                color: 'black',
                                padding: '1rem',
                                maxWidth: '600px', 
                                minWidth: '300px',
                                textAlign: 'left', 
                                marginLeft: '1rem', 
                            }}>
                                <h2 className="homepage-header">Pioneering Change in VFX Editing</h2>
                                <p className="text-body" style={{ margin: '1rem 0' }}>At VFX Editing, our focus is on enhancing the day-to-day workflows of VFX editors by automating time-consuming tasks that can detract from their core editing work.</p>
                                <p className="text-body" style={{ marginBottom: '2rem' }}>Our mission is to integrate functional AI into the toolkit of VFX editors, offering a solution that simplifies complex tasks and eliminates repetitive manual work.</p>
                                <p>Check out our new <strong>AAF Converter</strong> tool!</p>
                                {/* Add more text and elements as needed */}
                                <Link to="/upload_files" className="cta-button">
                                    AAF Converter
                                </Link>
                            </div>
                        </div>
                        <div class="intro-section">
                            <div class="intro-image-1">
                                <ImageFadeIn src="../../assets/img/contact_censor.png" alt="Feature" className="intro-image-img1" />
                            </div>
                            <div class="intro-text intro-textbox1">
                                <p>Our platform is crafted with the specific needs of VFX editors in mind, applying AI-driven automation to streamline those aspects of the editing process that are traditionally labor-intensive. This enables VFX editors to concentrate more on the creative and nuanced aspects of their projects, optimizing their time and enhancing their overall productivity.</p>
                            </div>
                        </div>

                        <div class="intro-section">
                            <div class="intro-image">
                                <ImageFadeIn src="../../assets/img/bg2.jpg" alt="Feature" className="intro-image-img2" />
                            </div>
                            <div class="intro-text intro-textbox2">
                                <p> By concentrating on functional AI, we aim to deliver a tool that VFX editors can seamlessly incorporate into their existing workflows. This tool is designed to be intuitive, requiring a minimal learning curve while offering substantial benefits in efficiency and effectiveness. It's about empowering VFX editors to focus on what they do best: crafting compelling visual narratives, free from the burden of monotonous tasks.</p>
                            </div>
                        </div>
                        <div class="intro-section">
                            <div class="intro-image">
                                <ImageFadeIn src="../../assets/img/bg1_half.jpg" alt="Feature" className="intro-image-img3" />
                            </div>
                            <div class="intro-text intro-textbox3">
                                <p> Our VFX Editing AAF Converter is designed to significantly streamline the workflow for VFX editors, enabling them to work more efficiently while enhancing the accuracy of VFX processes. This tool integrates seamlessly into existing workflows, minimizing the time spent on manual tasks and allowing editors to focus on creating stunning visual effects with precision and ease.</p>
                            </div>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '5rem', backgroundColor: 'white' }}>


                            <div class="image-container">
                                <ImageFadeIn
                                    src="../../assets/img/functionplot.webp"
                                    alt="Feature"
                                    className="feature-image"
                                />
                            </div>

                            {/* Text container */}
                            <div style={{
                                flex: 1,
                                color: 'black',
                                padding: '1rem',
                                maxWidth: '600px', // Adjust this value as needed to make the container narrower
                                minWidth: '300px',
                                textAlign: 'left',
                                marginLeft: '1rem' 
                            }}>
                                <h2 className="homepage-header">Our VFX Journey</h2>
                                <p className="text-body" style={{ margin: '1rem 0' }}>Our journey is rooted in a deep-seated passion for visual storytelling, with over a decade of experience in the VFX industry. It's here that we identified a crucial gap—the need for a more efficient and focused approach to VFX editing. This realization sparked the inception of VFX Editing, a venture aimed at transforming the VFX editing landscape specifically for VFX editors.</p>
                                {/* Add more text and elements as needed */}
                                <Link to="/contact" className="cta-button">
                                    Contact US
                                </Link>
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '5rem', backgroundColor: 'black' }}>

                            <div className="learn-more-textbox">
                                <div>
                                    <h2 className="homepage-header">AAF Converter</h2>
                                    <p className="text-body-white">To learn more about how our AAF Converter can help speed up your VFX Editing workflow, contact us!</p>
                                </div>
                                <Link to="/contact" className="cta-button">
                                    Learn More
                                </Link>
                            </div>

                            <div style={{ flex: 1, display: 'flex', maxWidth: '600px', justifyContent: 'flex-end' }}>
                                <ImageFadeIn
                                    src="../../assets/img/aafconverter.webp"
                                    alt="Feature"
                                    style={{
                                        maxWidth: '100%',
                                        height: 'auto',
                                        marginRight: '0rem',
                                        borderBottomLeftRadius: '15px',
                                        borderBottomRightRadius: '15px',
                                        borderTopRightRadius: '15px'
                                    }}
                                />
                            </div>
                        </div>
                        <div class="testimonials-container hidden">
                            <div class="testimonial testimonial-first">
                                <img src="../../assets/img/logo.png" alt="Monet Goode" class="testimonial-image" />
                                <blockquote>
                                    "It all begins with an idea. Maybe you want to launch a business."
                                    <cite>Monet Goode</cite>
                                </blockquote>
                            </div>

                            <div class="testimonial testimonial-second">
                                <img src="../../assets/img/logo.png" alt="Keith Finley" class="testimonial-image" />
                                <blockquote>
                                    "It all begins with an idea. Maybe you want to launch a business."
                                    <cite>Keith Finley</cite>
                                </blockquote>
                            </div>

                            <div class="testimonial testimonial-third">
                                <img src="../../assets/img/logo.png" alt="Channing Lee" class="testimonial-image" />
                                <blockquote>
                                    "It all begins with an idea. Maybe you want to launch a business."
                                    <cite>Channing Lee</cite>
                                </blockquote>
                            </div>
                        </div>
                        <div class="testimonials-container-temp">
                        </div>


                        <div class="footer-container contact-info">
                            <div class="footer-item">
                                <a href="/" class="email-link">Home</a>
                                <a href="/upload_files" class="email-link">AAF Converter</a>
                                <a href="/contact" class="email-link">Contact Us</a>
                            </div>
                            <div class="footer-item">
                                <button onClick={scrollToTop} class="button-to-top">
                                    <img src="../../assets/img/logo.png" alt="Back to Top" />
                                </button>
                            </div>
                            <div class="footer-item contact-info">
                                <a href="mailto:info@vfxediting.com" class="email-link">info@vfxediting.com</a>
                            </div>
                        </div>





                    </>
                )}
                <Suspense fallback={<div>Loading</div>}>
                    <Switch>
                        {
                            Routes.map((route, index) => {
                                return ((
                                    <Route
                                        path={route.path}
                                        key={index}
                                        component={route.component}
                                        exact={route.exact}
                                    />
                                ))
                            })
                        }
                    </Switch>
                </Suspense>
            </main>
        </React.Fragment>
    )
}


export default Base;
