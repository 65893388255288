import React , {Suspense} from 'react'
import './App.css';
import Base from './Layouts/base'
import { BrowserRouter as Router , Switch , Route } from 'react-router-dom'
import ResetPassword from './Views/Auth/reset';
import MFA from './Views/Auth/mfa';
import Recover from './Views/Auth/recover';

const Login = React.lazy(() => import('../src/Views/Auth/login'));
const ContactUs = React.lazy(() => import('./Views/Contact/ContactUS'));
function App() {
  return (
      <React.Fragment>
        <Router>
          <Suspense>
            <Switch>
              <Route exact path="/" render={(props) => <Base {...props} isHomepage={true} />} />
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/contact" render={props => <ContactUs {...props} />} />
              <Route exact path="/reset" render={props => <ResetPassword {...props} />} />
              <Route exact path="/mfa" render={props => <MFA {...props} />} />
              <Route exact path="/recover" render={props => <Recover {...props} />} />
              <Route path="/" render={(props) => <Base {...props} />} />
            </Switch>
          </Suspense>
        </Router>
      </React.Fragment>
  );
}

export default App;
