import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import checkAuth from '../../HOC/checkAuth';

const MFA = () => {
  const [mfaCode, setMfaCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const location = useLocation();

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage('');

    const checkIfRecover = () => {
      const queryParams = new URLSearchParams(location.search);
      const origin = queryParams.get('origin');
      return origin === 'recover';
    };

    const isRecover = checkIfRecover();

    // verify mfa, if is recovering, gives token with freeze permission, valid for 10 sec
    // otherwier gives token with aaf permission, valid for 24 hours. 
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}verify-mfa`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ mfaCode: mfaCode,  isRecover: isRecover}),
      credentials: 'include'
    });

    if (response.ok) {
      if (isRecover) {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}freeze-logout-account`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include'
        });
        const responseData = await response.json();
        if (response.ok) {
          alert('Note: Account confirmation may take up to 48 hours. During this time, your account will be frozen. We have sent you a confirmation email');
          window.location.href = '/';
        } else {
          setErrorMessage(responseData.error);
        }
        
      } else {
        window.location.href = '/upload_files';
      }
    } else {
      setIsSubmitting(false);
      setMfaCode('');
      setErrorMessage('Two-factor authentication failed.');
    }
  }, [mfaCode, location.search]);

  useEffect(() => {
    if (mfaCode.length === 6) {
      handleSubmit(new Event('submit'));
    }
  }, [mfaCode, handleSubmit]);

  return (
    <div className="wrapper">
      <div className="mfa-box">
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
          <a href="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
            <img style={{ maxHeight: '50px', marginRight: '10px' }} src="/assets/img/logo.png" alt="Logo" />
          </a>
        </div>
        <h2>Enter the 6-digit code found in your authenticator app</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="XXXXXX"
            className="mfa-input"
            value={mfaCode}
            onChange={(e) => setMfaCode(e.target.value)}
            maxLength="6"
          />
          {errorMessage && <p className="error-message" style={{ color: 'red' }}>{errorMessage}</p>}
          <button
            type="submit"
            className={`btn bg-gradient-info mb-0 font20 login-btn-width ${isSubmitting ? 'dimmed' : ''}`}
          >
            Verify
          </button>
        </form>
      </div>
    </div>
  );
};

export default checkAuth(MFA, 'mfa');
