import {lazy} from 'react';

const Login = lazy(() => import("../src/Views/Auth/login"));
const UploadFiles = lazy(() => import("../src/Views/FileUploads/uploadFiles"));
export const Routes = [
    {
        path:"/login",
        component:Login,
        exact:true
    },
    {
        path:"/upload_files",
        component:UploadFiles,
        exact:true
    },

]