import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { logout } from '../utils/auth';
import Cookies from 'js-cookie';

const NavBar = () => {

    // Define a common style for all buttons
    const location = useLocation();

    // Determine if the current route is the homepage
    const isHomePage = location.pathname === '/';

    const userEmail = Cookies.get('userEmail');
    const Authenticated = !!userEmail;

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const updateDimensions = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    const buttonStyle = {
        cursor: 'pointer',
        background: 'transparent',
        border: '0px',
        color: isHomePage ? 'white' : 'black',
        padding: windowWidth > 768 ? '10px 20px' : '4px 8px', // smaller padding on smaller screens
        fontSize: windowWidth > 768 ? '1.1rem' : '0.9rem', // smaller font size on smaller screens
    };
    const activeStyle = {
        cursor: 'pointer',
        background: 'green',
        border: '0px',
        color: 'white'
    };

    return (
        <nav className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" navbar-scroll="true">
            <div className="container-fluid py-1 px-3">
                <div className="d-flex justify-content-between align-items-center w-100">
                    {/* Company logo button at the very left */}
                    <NavLink to="/" className="logo-button"></NavLink>
                    <div className="d-flex justify-content-end align-items-center">
                        {/* User email */}
                        {Authenticated && (
                            <div className={isHomePage ? "welcomeMessage homePageWelcome" : "welcomeMessage"}>
                                <p>Welcome, {decodeURIComponent(userEmail)}!</p>
                            </div>
                        )}
                        {/* Home button */}
                        <NavLink to="/" className="btn btn-primary me-2" activeClassName="active" style={buttonStyle}>
                            <i className="fas fa-home"></i> Home
                        </NavLink>
                        {/* AAF Converter button */}
                        <NavLink to="/upload_files" className="btn btn-primary me-2" activeClassName="active" style={buttonStyle} activeStyle={activeStyle}>
                            <i className="fas fa-upload"></i> AAF Converter
                        </NavLink>
                        {/* Contact US button */}
                        <NavLink to="/contact" className="btn btn-primary me-2" activeClassName="active" style={buttonStyle} activeStyle={activeStyle}>
                            <i className="fas fa-envelope"></i> Contact
                        </NavLink>
                        {/* Sign out button */}
                        {Authenticated ? (
                            <button onClick={logout} className="btn btn-primary" style={buttonStyle}>
                                <i className="fa fa-power-off me-sm-1"></i>
                                <span className="d-sm-inline d-none">Sign Out</span>
                            </button>
                        ) : (
                            <NavLink to="/login" className="btn btn-primary" style={buttonStyle}>
                                <i className="fa fa-sign-in-alt me-sm-1"></i>
                                <span className="d-sm-inline d-none">Sign In</span>
                            </NavLink>
                        )}
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default NavBar;
