import React, { useState, useEffect } from 'react';
import { Rings } from 'react-loader-spinner';
import checkAuth from '../../HOC/checkAuth';

const ResetPassword = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [qrcode, setQrcode] = useState('');

    useEffect(() => {
        const fetchQrcode = async () => {
            try {
                setQrcode('');
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}ping/reset`, {
                    method: 'GET',
                    credentials: 'include',
                });
                const data = await response.json();
                setQrcode(data.qrcode);
            } catch (error) {
                console.error('Error fetching QR code:', error);
            }
        };

        fetchQrcode();
    }, []);

    const handleNewPasswordChange = (event) => {
        setNewPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorMessage('');
        const passwordConstraints = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])[A-Za-z\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]{8,}$/;


        if (!passwordConstraints.test(newPassword)) {
            setErrorMessage('Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character.');
            return;
        }

        if (newPassword !== confirmPassword) {
            setErrorMessage('Passwords do not match');
            return;
        }

        setLoading(true);
        try {
            console.log("Passwords match, proceed with the reset");
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}reset`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ password: newPassword }),
                credentials: 'include'
            });
            const responseData = await response.json();
            if (response.ok) {
                window.location.href = "/login";
            } else {
                setErrorMessage(responseData.error);
            }
        } catch (error) {
            console.error('Error during reset:', error);
            setErrorMessage('An unexpected error occurred.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ padding: 20, maxWidth: 700, margin: 'auto', border: '1px solid #ccc', borderRadius: 4 }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
                <a href="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                    <img style={{ maxHeight: '50px', marginRight: '10px' }} src="/assets/img/logo.png" alt="Logo" />
                </a>
            </div>
            <h2>Setup authenticator app</h2>
            <p>
                Authenticator apps and browser extensions like
                <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_AU&gl=US" target="_blank" rel="noopener noreferrer" className="mfa_link" style={{ marginLeft: '5px' }}>Google Authenticator</a>,
                <a href="https://authy.com/" target="_blank" rel="noopener noreferrer" className="mfa_link">Authy</a>,
                <a href="https://www.microsoft.com/en-us/security/mobile-authenticator-app" target="_blank" rel="noopener noreferrer" className="mfa_link">Microsoft Authenticator</a>, etc. generate one-time passwords that are used as a second factor to verify your identity when prompted during sign-in.
            </p>
            <p>Use an authenticator app to scan the QR code</p>
            {qrcode && (
                <div style={{ marginBottom: 20, position: 'relative', display: 'inline-block' }}>
                    <div>
                        <img src={qrcode} alt="QR Code" style={{ width: 200, height: 200, margin: '10px 0' }} />
                        <img
                            src="/assets/img/logo.png"
                            alt="Overlay"
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 50,
                                height: 50
                            }}
                        />
                    </div>
                </div>
            )}
            <h3>Reset Password</h3>
            <p>
                Disclaimer: Resetting your password will disable the temporary password and you will no longer be able to use it to sign in. In the event that you lose your new password, it may take up to 48 hours to replace. Approval from VFX Editing will be required for this process. (This page will be deactivated after 10 minutes.)
            </p>
            <form onSubmit={handleSubmit}>
                <div style={{ marginBottom: 20 }}>
                    <label>New Password</label>
                    <input
                        type="password"
                        value={newPassword}
                        onChange={handleNewPasswordChange}
                        style={{ width: '100%', padding: 10, margin: '5px 0' }}
                    />
                </div>
                <div style={{ marginBottom: 20 }}>
                    <label>Confirm Password</label>
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        style={{ width: '100%', padding: 10, margin: '5px 0' }}
                    />
                </div>
                {loading && <Rings visible={true} height="80" width="80" color="#4fa94d" ariaLabel="rings-loading" />}
                {errorMessage && <p className="error-message" style={{ color: 'red' }}>{errorMessage}</p>}
                <button type="submit" className="btn bg-gradient-info mb-0 font20 login-btn-width">Submit</button>
            </form>
        </div>
    );
};

export default checkAuth(ResetPassword, 'reset');
